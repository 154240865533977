import React from 'react';

import { api } from 'api';

import { Text } from 'components/Text';

import css from './UserDetails.module.css';
import { UserSocial } from './UserSocial';

interface UserDetailsProps {
  user: api.Maybe<api.User>;
  canEditCalendar: boolean;
}

export const UserDetails = React.memo<UserDetailsProps>(({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <section className={css.container}>
      {user.description ? (
        <Text.Regular className={css.descriptionText}>
          {user.description}
        </Text.Regular>
      ) : null}
      <UserSocial user={user} />
      {/* <Calendar
          className={css.calendar}
          isAdmin={canEditCalendar}
          calendarEvents={timetable}
        /> */}
    </section>
  );
});
