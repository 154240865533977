import React from 'react';

import { api } from 'api';

import { AvatarUser } from 'components/Avatar';
import { Icon } from 'components/Icons';
import { Text } from 'components/Text';

import css from './UserThumbnail.module.css';

interface UserThumbnailProps {
  user: api.User;

  renderControls?: React.ReactNode;
}

export const UserThumbnail = React.memo<UserThumbnailProps>(
  ({ user, renderControls }) => {
    return (
      <article className={css.container}>
        <div className={css.avatarWrapper}>
          <AvatarUser className={css.userAvatar} user={user} />
        </div>

        <section className={css.infoContainer}>
          <article className={css.userDetails}>
            <div className={css.usernameSection}>
              <Text.H2 className={css.userName}>@{user.username}</Text.H2>
              {user.roles?.includes(api.UserRole.Streamer) ? (
                <Icon.StreamerCheck className={css.streamerIcon} />
              ) : null}
            </div>
          </article>
        </section>
        {renderControls ? (
          <section className={css.controlsContainer}>{renderControls}</section>
        ) : null}
      </article>
    );
  },
);
