import React from 'react';

import { api } from 'api';

import { Button } from 'components/Button';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { useTranslations } from 'components/Language';
import { Text } from 'components/Text';
import { Slide } from 'containers/ModalAuthOnboarding/helpers';
import { useText } from 'text';

import css from './ShopBanner.module.css';

interface ShopBannerProps {
  me: api.Maybe<api.User>;
  user: api.User;
  gameId: api.Maybe<api.GameId>;
  wrapperClassName?: string;
  isDesriptionLarge?: boolean;
}

export const ShopBanner: React.FC<ShopBannerProps> = ({
  me,
  user,
  gameId,
  wrapperClassName,
  isDesriptionLarge = false,
}) => {
  const translations = useTranslations();
  const text = useText(state => state.event);
  const onboardingModal = useOnboardingModal();

  if (!user.shopUrl || !user.username) {
    return null;
  }

  const renderButton = () => {
    if (me) {
      return (
        <a
          href={user.shopUrl}
          rel="noreferrer"
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          target="_blank"
        >
          <Button
            color="black"
            style={{ width: '100%', color: 'var(--green) !important' }}
            onClick={() => {
              return null;
            }}
          >
            {text.actions.shop.shopAction}
          </Button>
        </a>
      );
    }

    return (
      <Button
        color="black"
        style={{ width: '100%', color: 'var(--green) !important' }}
        onClick={() => {
          // set to local storage to have access after auth in modal
          localStorage.setItem('shopUrl', user.shopUrl || '');

          onboardingModal.open({
            title: text.actions.shop.authTitle,
            slides: [Slide.ButtonsAuth],
            onSuccessAfterClose: () => {
              const shopUrl = localStorage.getItem('shopUrl');
              if (shopUrl) {
                // window.open(shopUrl, '_blank', 'noopener');
                window.location.href = shopUrl;
              }
              localStorage.removeItem('shopUrl');
            },
            canBeClosed: true,
          });
        }}
      >
        {text.actions.shop.shopAction}
      </Button>
    );
  };

  const renderDescription = () => {
    if (isDesriptionLarge) {
      return (
        <Text.R3 style={{ color: 'var(--dark-12)', marginBottom: '4px' }}>
          {gameId === api.GameId.PubgMobile
            ? text.actions.shop.pubgShopBannerDescription(user.username || '')
            : text.actions.shop.shopBannerDescription(user.username || '')}
        </Text.R3>
      );
    }

    return (
      <Text.R4 style={{ color: 'var(--dark-12)', marginBottom: '4px' }}>
        {gameId === api.GameId.PubgMobile
          ? text.actions.shop.pubgShopBannerDescription(user.username || '')
          : text.actions.shop.shopBannerDescription(user.username || '')}
      </Text.R4>
    );
  };

  return (
    <div className={wrapperClassName || css.userShopBannerContainer}>
      <div className={css.userShopBanner}>
        <Text.R2 bold style={{ color: 'var(--dark)', marginBottom: '2px' }}>
          {text.actions.shop.shopBannerTitle(
            user.username || '',
            gameId ? translations.gameTitle(gameId).full : '',
          )}
        </Text.R2>
        {renderDescription()}
        {renderButton()}
      </div>
    </div>
  );
};
