import React from 'react';

import { LayoutApp, LayoutAppProps } from '../LayoutApp';

import { ContentError } from './ContentError';
import { ContentLoading } from './ContentLoading';

import css from './LayoutColumns.module.css';

import type { ApolloError } from '@apollo/client';

export interface LayoutColumnsProps extends LayoutAppProps {
  renderRightSection: React.ReactNode;
  error?: ApolloError;
  isLoading?: boolean;
}

export const LayoutColumns: React.FC<LayoutColumnsProps> = ({
  children,
  renderRightSection,
  isLoading = false,
  error,
  ...props
}) => {
  return (
    <LayoutApp {...props}>
      <section className={css.container}>
        <div className={css.wrapper}>
          <section className={css.columnCenterContainer} id="layout_content">
            <section className={css.columnCenterWrapper}>
              {children}
              {isLoading ? <ContentLoading /> : null}
              {error ? <ContentError error={error} /> : null}
            </section>
          </section>

          <aside className={css.columnRightContainer}>
            <section className={css.columnRightWrapper}>{renderRightSection}</section>
          </aside>
        </div>
      </section>
    </LayoutApp>
  );
};
