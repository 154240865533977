import React, { useCallback } from 'react';

import { analytics } from 'analytics';
import { api } from 'api';

import { Icon, IconProps } from 'components/Icons';
import { Text } from 'components/Text';

import { useToasts } from 'components/Toast';
import { useText } from 'text';
import { copyToClipboard } from 'utils/main';

import css from './UserDetails.module.css';

interface UserProfileInfoProps {
  user: api.User;
}

export const UserSocial = React.memo<UserProfileInfoProps>(({ user }) => {
  if (!user.twitch && !user.battlenet && !user.discord && !user.twitter) {
    return null;
  }

  return (
    <div className={css.userSocial}>
      {user.twitch ? (
        <SocialLinkIcon
          Icon={Icon.TwitchOutlined}
          link={`https://twitch.tv/${user.twitch.login}`}
          login={`@${user?.twitch?.username}`}
          value={user.twitch.username}
          onSocialClick={() => analytics.clickTwitchCopylink(user?.id, user.username)}
        />
      ) : null}
      {user.discord ? (
        <SocialLinkIcon
          Icon={Icon.DiscordOutlined}
          login={user.discord.username}
          value={user.discord.username}
          onSocialClick={() =>
            analytics.clickDiscordCopylink(user?.id, user.username)
          }
        />
      ) : null}

      {user.twitter ? (
        <SocialLinkIcon
          Icon={Icon.TwitterOutlined}
          login={`@${user?.twitter?.username}`}
          value={user.twitter.username}
          onSocialClick={() =>
            analytics.clickTwitterCopylink(user?.id, user.username)
          }
        />
      ) : null}
      {user.battlenet ? (
        <SocialLinkIcon
          Icon={Icon.BattlenetOutlined}
          login={user.battlenet.battletag}
          value={user.battlenet.battletag}
          onSocialClick={() =>
            analytics.clickBattlenetCopylink(user?.id, user.username)
          }
        />
      ) : null}
    </div>
  );
});

interface SocialLinkProps {
  value: string;
  login: api.Maybe<string>;
  Icon: React.FC<IconProps>;
  onSocialClick: () => void;
  link?: string;
}

const SocialLinkIcon = React.memo<SocialLinkProps>(
  ({ value, login, Icon, link, onSocialClick = () => undefined }) => {
    const text = useText(state => state.userGames.card.toastLinkCopied);
    const { alert } = useToasts();

    const handleCopy = useCallback(() => {
      copyToClipboard(value);
      alert({ type: 'info', message: text });
    }, [value]);

    return (
      <>
        <Icon className={css.socialIcon} />
        <a
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          onClick={event => {
            onSocialClick();

            if (!link) {
              event.preventDefault();
              handleCopy();
            }
          }}
        >
          <Text.R2 className={css.socialValue}>{login}</Text.R2>
        </a>
      </>
    );
  },
);
