import { UrlObject } from 'url';

import React, { useMemo } from 'react';

import cx from 'classnames';
import { useRouter } from 'next/router';

import { api } from 'api';
import { useGameMeta } from 'components/GamePreview';
import { ShopBanner } from 'components/ShopBanner';
import { Tabs } from 'components/Tabs';

import { LayoutColumns, LayoutColumnsProps } from '../LayoutColumnsNew';

import { useUserProfileTabs } from './helpers';
import css from './LayoutUserProfile.module.css';
import { UserControls } from './UserControls';
import { UserDetails } from './UserDetails';
import { UserThumbnail } from './UserThumbnail';

type ParentProps = Omit<
  LayoutColumnsProps,
  'backgroundImg' | 'tabs' | 'activeTab' | 'renderRightSection'
>;

interface LayoutUserProfileProps extends ParentProps {
  user: api.Maybe<api.User>;
  hasRenderedOnServer?: boolean;
  linkGame?: api.Maybe<api.Game>;
}

export const LayoutUserProfile: React.FC<LayoutUserProfileProps> = ({
  me,
  user,
  linkGame,
  children,
  ...props
}) => {
  const router = useRouter();
  const tabs = useUserProfileTabs(user, me);
  const gameMeta = useGameMeta(linkGame, user);

  const userGameId = useMemo(() => {
    if (!user?.lfg?.length) {
      return undefined;
    }

    if (user.lfg.find(lfg => lfg.gameId === api.GameId.PubgMobile)) {
      return api.GameId.PubgMobile;
    }

    if (user.lfg.length === 1) {
      return user.lfg[0].gameId;
    }

    return undefined;
  }, [user?.lfg?.length]);

  const Controls = useMemo<React.ReactNode>(() => {
    return <UserControls isMe={me?.id === user?.id} user={user} />;
  }, [me, user]);

  const renderContent = (): React.ReactNode => {
    if (!user) {
      return null;
    }

    return (
      <>
        <object className={css.backgroundContainer}>
          <div
            className={cx(css.backgroundImage, {
              [css.backgroundImageUnset]: !user.backgroundUrl,
            })}
            style={
              user.backgroundUrl
                ? {
                    backgroundImage: `url("${user.backgroundUrl}")`,
                  }
                : {}
            }
          />
        </object>

        <UserThumbnail renderControls={Controls} user={user} />
        <UserDetails canEditCalendar={false} user={user} />
        <ShopBanner
          isDesriptionLarge
          gameId={userGameId}
          me={me}
          user={user}
          wrapperClassName={css.userShopBannerContainer}
        />
        <section className={css.contentTabsContainer}>
          <Tabs currentLink={{ pathname: router.asPath } as UrlObject} tabs={tabs} />
        </section>

        <section className={css.contentContainer}>{children}</section>
      </>
    );
  };

  return (
    <LayoutColumns
      withHeaderBackground
      me={me}
      meta={gameMeta}
      renderRightSection={null}
      {...props}
      title={'@' + (user?.username || '')}
    >
      {renderContent()}
    </LayoutColumns>
  );
};
