import React from 'react';

import { analytics } from 'analytics';

import { api } from 'api';

import { Button, ButtonIcon } from 'components/Button';
import { useModal } from 'components/ContextModal';
import { Icon } from 'components/Icons';
import { useToasts } from 'components/Toast';
import { ButtonFollowUser } from 'components/UserCard';
import { useText } from 'text';
import { dynamic } from 'utils/dynamic';
import { copyToClipboard } from 'utils/main';

import css from './UserControls.module.css';

const SlideoverSettings = dynamic(() =>
  import('components/SlideoverSettings').then(m => m.SlideoverSettings),
);

export interface UserControlsProps {
  isMe: boolean;
  user: api.Maybe<api.User>;
}

export const UserControls = React.memo<UserControlsProps>(({ isMe, user }) => {
  const modal = useModal();
  const { alert } = useToasts();

  const text = useText(state => state.userSettings.page);
  const copied = useText(state => state.userGames.card.toastLinkCopied);

  if (!user) {
    return null;
  }

  if (!isMe) {
    return (
      <div className={css.userActions}>
        <ButtonFollowUser
          profile
          className={css.followButton}
          playerEventCategory="profileBlock"
          refetchQueries={[
            {
              query: api.GetUserFollowersDocument,
              variables: { username: user.username },
            },
          ]}
          user={user}
        />
      </div>
    );
  }

  return (
    <div className={css.userActions}>
      <Button
        color="secondary"
        onClick={() => {
          analytics.clickProfileCopylink(user?.id, user.username);
          copyToClipboard(location.origin + `/${user.username}`);
          alert({ message: copied });
        }}
      >
        {text.copyLink}
      </Button>

      <ButtonIcon
        Icon={Icon.Settings}
        onClick={() => {
          analytics.clickSettings(user?.id, user.username);
          modal.open(SlideoverSettings.Component, {});
        }}
        onMouseOver={() => SlideoverSettings.prefetch()}
      />
    </div>
  );
});
