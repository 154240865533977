import { UrlObject } from 'url';

import { useMemo } from 'react';

import { api } from 'api';

import { TabItem } from 'components/Tabs/Tabs';
import { useText } from 'text';
import { links } from 'utils/links';

export function useUserProfileTabs(
  user: api.Maybe<api.User>,
  me: api.Maybe<api.User>,
  isMobile?: boolean,
): TabItem<UrlObject>[] {
  const text = useText(state => state.user);

  const tabs = useMemo<TabItem<UrlObject>[]>(() => {
    if (!user) {
      return [];
    }

    const isMe = me?.id == user.id;

    if (isMobile) {
      const tabs: TabItem<UrlObject>[] = [
        {
          title: text.navigation.events,
          link: links.user.history(user.username),
        },
        {
          title: text.navigation.about,
          link: links.user.home(user.username),
        },
      ];

      return tabs;
    }

    const tabs: TabItem<UrlObject>[] = [
      {
        title: text.navigation.events,
        link: links.user.history(user.username),
      },
      {
        title: text.navigation.about,
        link: links.user.home(user.username),
      },
      {
        title: text.navigation.links,
        link: links.user.links(user.username),
      },
      {
        title: `${text.navigation.followingWithoutCount}`,
        link: links.user.following(user.username),
      },
      {
        title: `${text.navigation.followers(user.followersCount || 0)}`,
        link: links.user.followers(user.username),
      },
      // ...(isMe
      //   ? [
      //       {
      //         title: text.navigation.visitors,
      //         link: links.user.visitors(user.username),
      //       },
      //     ]
      //   : []),
    ];

    return tabs;
  }, [user?.username, me]);

  return tabs;
}

export enum EditOptionType {
  Delete,
  Neutral,
}

export type DeleteGoal = 'member';

export type DeleteModal = {
  isOpen: boolean;
  deleteGoal: DeleteGoal;
};

export interface EditOptions {
  label: string;
  type: EditOptionType;
  isShown?: boolean;
  onClick: () => void;
}

export type Tab =
  | 'games'
  | 'links'
  | 'history'
  | 'following'
  | 'following'
  | 'visitors';

export const limit = 10;
export const eventsLimit = 100;
export const defaultThemeBackground =
  'https://storage.googleapis.com/lfgcdn/assets/bg2/light-lost.webp';
