import { UrlObject } from 'url';

import React from 'react';

import cx from 'classnames';
import Link from 'next/link';

import { Text } from '../Text';

import css from './Tabs.module.css';

export interface TabItem<T> {
  title: string;
  link: T;
}

export interface TabsProps<T> {
  currentLink: T;
  tabs: TabItem<T>[];
  isStatic?: boolean;
  className?: string;
  onTabClick?: (link: T) => void;
  renderRightSection?: () => React.ReactNode;
}

function TabsFn<T>({
  currentLink,
  tabs,
  renderRightSection,
  className,
  isStatic = false,
  onTabClick = () => undefined,
}: TabsProps<T>) {
  return (
    <nav className={cx(css.container, className)}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          active={
            currentLink?.hasOwnProperty('pathname') &&
            tab.link?.hasOwnProperty('pathname')
              ? (currentLink as any).pathname === (tab.link as any).pathname
              : tab.link === currentLink
          }
          isStatic={isStatic}
          link={tab.link}
          title={tab.title}
          onTabClick={() => onTabClick(tab.link)}
        />
      ))}
      {renderRightSection ? (
        <>
          <div className={css.gap} />
          <div className={css.rightSection}>{renderRightSection()}</div>
        </>
      ) : null}
    </nav>
  );
}
export const Tabs = React.memo(TabsFn) as typeof TabsFn;

interface TabsContainerProps {
  className?: string;
  children?: JSX.Element | Array<JSX.Element | false> | null | string;
}

export const TabsContainer: React.FC<TabsContainerProps> = ({
  children,
  className,
}) => {
  return <nav className={cx(css.container, className)}>{children}</nav>;
};

interface TabProps<T> {
  title: string;
  link: T;
  active: boolean;
  isStatic?: boolean;
  className?: string;
  disabled?: boolean;

  onTabClick?: () => void;
  onLinkClick?: () => void;
}
export function TabFn<T>({
  title,
  link,
  className,
  active,
  isStatic = false,
  disabled = false,
  onTabClick = () => undefined,
  onLinkClick = () => undefined,
}: TabProps<T>) {
  if (isStatic) {
    return (
      <div
        className={cx(
          css.tab,
          { [css.tabActive]: active, [css.tabDisabled]: disabled },
          className,
        )}
        onClick={onTabClick}
      >
        <li>
          <Text.Regular
            semibold
            className={cx(css.tabWrapper, { [css.tabWrapperActive]: active })}
            size={3}
          >
            {title}
          </Text.Regular>
        </li>
      </div>
    );
  }

  return (
    <Link
      className={cx(css.tab, { [css.tabActive]: active }, className)}
      href={link as UrlObject}
      onClick={onLinkClick}
    >
      <li>
        <Text.Regular
          semibold
          className={cx(css.tabWrapper, { [css.tabWrapperActive]: active })}
          size={3}
        >
          {title}
        </Text.Regular>
      </li>
    </Link>
  );
}

export const Tab = React.memo(TabFn) as typeof TabFn;
